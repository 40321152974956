@font-face {
  font-family: "NotoSans";
  src: url(/assets/fonts/NotoSans-Black.ttf);
  font-weight: 800; // font-display: block;
}

@font-face {
  font-family: "NotoSans";
  src: url(/assets/fonts/NotoSans-Bold.ttf);
  font-weight: 700; // font-display: block;
}

@font-face {
  font-family: "NotoSans";
  src: url(/assets/fonts/NotoSans-SemiBold.ttf);
  font-weight: 600; // font-display: block;
}

@font-face {
  font-family: "NotoSans";
  src: url(/assets/fonts/NotoSans-Medium.ttf);
  font-weight: 500; // font-display: block;
}

@font-face {
  font-family: "NotoSans";
  src: url(/assets/fonts/NotoSans-Regular.ttf);
  font-weight: 400; // font-display: block;
}

@font-face {
  font-family: "NotoSans";
  src: url(/assets/fonts/NotoSans-Light.ttf);
  font-weight: 300; // font-display: block;
}

@font-face {
  font-family: "NotoSans";
  src: url(/assets/fonts/NotoSans-ExtraLight.ttf);
  font-weight: 200; // font-display: block;
}

@font-face {
  font-family: "NotoSans";
  src: url(/assets/fonts/NotoSans-Thin.ttf);
  font-weight: 100;
}
