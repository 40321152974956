

.display-2 {
  font-family: "NotoSans";
  font-size: 48px;
  line-height: 1.6;
  font-weight: 600;
}

.display-1 {
  font-family: "NotoSans";
  font-size: 34px;
  line-height: 1.6;
  font-weight: 600;
}

.headline {
  font-family: "NotoSans";
  font-size: 25px;
  line-height: 1.6;
  font-weight: 600;
}

.title {
  font-family: "NotoSans";
  font-size: 24px;
  line-height: 1.6;
  font-weight: 600;
}


.subheading-2 {
  font-family: "NotoSans";
  font-size: 20px;
  line-height: 1.5;
  font-weight: 600;
}


.subheading-1 {
  font-family: "NotoSans";
  font-size: 16px;
  line-height: 1.5;
  font-weight: 600;
}

.body-2 {
  font-family: "NotoSans";
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
}

.body-1 {
  font-family: "NotoSans";
  font-size: 14px;
  line-height: 1.4;
  font-weight: 400;
}

.caption {
  font-family: "NotoSans";
  font-size: 12px;
  line-height: 1.32;
  font-weight: 400;
}

.input {
  font-family: "NotoSans";
  font-size: 16px;
  line-height: 1.32;
  font-weight: inherit;
}

.button {
  font-family: "NotoSans";
  font-size: 16px;
  line-height: inherit;
  font-weight: 600;
}

.text-gray {
  color: #777777;
}

